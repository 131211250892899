import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Button, Container, Grid, Divider } from '@mui/material';
import AppAppBar from '../../GeneralComponents/AppAppBar';
import Footer from './components/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Theme from '../../GeneralComponents/Theme';
import Hero from './components/Hero';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const codeStyle = {
  backgroundColor: '#2e2e2e',
  color: '#ffffff',
  padding: '10px',
  borderRadius: '5px',
  overflowX: 'auto',
  fontFamily: 'monospace',
};

const BlockchainPage = () => {
  const [value, setValue] = useState(0);
  const [mode, setMode] = useState("light");
  const theme = createTheme(Theme(mode));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleDownload = async (platform) => {
    try {
      const response = await fetch(`/api/download/${platform}`);
      const data = await response.json();
      window.location.href = data.downloadUrl;
    } catch (error) {
      console.error("Error downloading app:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Download and install the MyWallet CLI Application to interact with the NFT Solutions blockchain.
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="platform tabs">
          <Tab icon={<i className="fab fa-linux"></i>} label="Linux" />
          <Tab icon={<i className="fab fa-apple"></i>} label="macOS" />
          <Tab icon={<i className="fab fa-windows"></i>} label="Windows" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant="h6">Linux Installation</Typography>
          <ol>
            <li>Open a terminal and navigate to the directory containing your mywallet executable.</li>
            <li>If the file is not executable, run the following command to make it executable:</li>
            <Box component="pre" sx={codeStyle}><code>chmod +x ./mywallet-linux</code></Box>
            <li>Execute the following command to install mywallet globally. You may need to prepend <code>sudo</code> for permissions:</li>
            <Box component="pre" sx={codeStyle}><code>./mywallet-linux install</code></Box>
            <li>Follow the prompt and type <code>y</code> to confirm the installation.</li>
            <li>If needed, manually add <code>~/.local/bin</code> to your PATH.</li>
            <li>Verify installation by running <code>mywallet</code> from any terminal window.</li>
          </ol>
          <Typography variant="body2" color="textSecondary" paragraph>
            Note: Using <code>sudo</code> may be necessary to grant the required permissions for installation.
          </Typography>
          <Button variant="contained" onClick={() => handleDownload('linux')}>
            Download for Linux
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6">macOS Installation</Typography>
          <Typography variant="body1">Coming soon...</Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6">Windows Installation</Typography>
          <ol>
            <li>Open Command Prompt and navigate to the directory containing your mywallet.exe.</li>
            <li>Execute the following command to install mywallet globally:</li>
            <Box component="pre" sx={codeStyle}><code>mywallet-windows.exe install</code></Box>
            <li>Follow the prompt and type <code>y</code> to confirm the installation.</li>
            <li>If needed, manually add the installation directory to your PATH.</li>
            <li>Verify installation by running <code>mywallet</code> from any Command Prompt window.</li>
          </ol>
          <Button variant="contained" onClick={() => handleDownload('windows')}>
            Download for Windows
          </Button>
        </TabPanel>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Command Guide
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                init Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Initializes your wallet.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet init</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet init
Initialization successful. Local storage is set up.</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command sets up the local environment for your wallet, ensuring that the database is ready to store NFT transactions and other related data.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                sync Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Synchronizes your wallet with the blockchain, fetching the latest transactions and block headers.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet sync</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet sync
Syncing...
[====================] 100%</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command resets and initializes the database, then connects to the blockchain to download the latest data. A progress bar indicates the synchronization status.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                status Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Displays the current synchronization status and wallet information.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet status</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet status
Run the 'sync' command to fetch the latest blockchain data and your wallet transactions.</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command checks the latest block height in your local database and provides instructions on how to update your data.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                list Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Displays NFT transactions for a given wallet ID.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet list [wallet_id]</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet list 12345
NFT transactions for owner ID 12345:
- Type: Purchase, NFTID: abc123, Series: 1, Edition: 1, Title: "Sunset", Created: 2024-10-14 15:04:05, Updated: 2024-10-14 15:04:05</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command retrieves and displays all NFT transactions associated with the specified wallet ID.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                verify Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Verifies an NFT and its associated transactions using metadata hash, series + edition numbers, or NFT ID.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet verify --metadatahash=&lt;hash&gt;
mywallet verify --series=&lt;series&gt; --edition=&lt;edition&gt;
mywallet verify --nftid=&lt;nft_id&gt;</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet verify --nftid=abc123
Transactions:
Type: Art, NFTID: abc123, Series: 1, Edition: 1, Owner: John Doe, CreatedAt: 2023-10-14 15:04:05</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command allows you to verify NFT transactions by providing one of the three identifiers: metadata hash, series + edition, or NFT ID.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                serve Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Sets up your local blockchain to be visible to others on the same network, allowing them to sync from your node.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet serve</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet serve
Your local IP address is 192.168.1.10 -- You may share this with others to allow them to sync from you.
Serving on 192.168.1.10:50000</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command allows others to connect to your computer in order to sync their blockchain data from yours.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                sync-from Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Syncs your local blockchain from another computer using its IP address.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet sync-from [ipaddress]</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet sync-from 192.168.1.10
Synchronization from server completed successfully.</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command connects to another node specified by the IP address and syncs the blockchain data to your local database.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                view Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Views NFT images for a given owner ID, downloading them if necessary.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet view [wallet_id]</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ mywallet view 12345
=== Your NFT Gallery ===
[1] "Sunset" (Series 1, Edition 1)
File AppData/1-Sunset-1.jpeg downloaded successfully.
Enter the number of the NFT to open in default viewer, or 'q' to quit:</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                - Explanation: This command retrieves and displays NFT images associated with the specified owner ID, downloading them if they are not already present locally.
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                version Command
              </Typography>
              <Typography variant="body1" paragraph>
                What it does: Displays the current version of the application.
              </Typography>
              <Typography variant="body1" paragraph>
                Usage:
                <Box component="pre" sx={codeStyle}><code>mywallet version</code></Box>
              </Typography>
              <Typography variant="body1" paragraph>
                Example:
                <Box component="pre" sx={codeStyle}><code>$ 1.0.6</code></Box>
              </Typography>
              <Divider />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default BlockchainPage;