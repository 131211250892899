import React, { useState } from "react";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import MUI CheckCircleIcon

const NftDownloadButton = ({ imageUrl, title, uuid }) => {
  const [downloaded, setDownloaded] = useState(false); // State to track download status

  const handleDownload = async () => {
    try {
      // Encode the imageUrl to ensure it's safely passed as a query parameter
      const encodedImageUrl = encodeURIComponent(imageUrl);
      // Append the encoded imageUrl as a query parameter to the request URL
      const response = await fetch(`/api/nft/download-image?imageUrl=${encodedImageUrl}`);
      if (!response.ok) throw new Error("Network response was not ok");
  
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
  
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = `${uuid}-${title.replace(/\s+/g, '-')}.jpeg`; // Assuming you want to keep the file extension
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleDownload}
      disabled={downloaded} // Disable button after successful download
      startIcon={downloaded ? <CheckCircleIcon /> : null} // Show checkmark icon if downloaded
    >
      {downloaded ? "Downloaded" : "Download NFT (Image Only)"} 
    </Button>
  );
};

export default NftDownloadButton;
