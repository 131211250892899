import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Grid, Paper, Typography, TextField, Button, List, ListItem,
  ListItemText, ListItemAvatar, Avatar, Divider, IconButton, CircularProgress,
  useMediaQuery, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Send as SendIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import io from 'socket.io-client';
import axios from 'axios';
import moment from 'moment';
import AuthService from '../../clientUtils/auth';
import { useLocation, useNavigate } from 'react-router-dom';

const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: 'calc(100vh - 32px)',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 16px)',
    padding: theme.spacing(2),
  },
}));

const MessageArea = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
});

const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isCurrentUser'
})(({ theme, isCurrentUser }) => ({
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  maxWidth: '70%',
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser ? theme.palette.primary.main : theme.palette.grey[200],
  color: isCurrentUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const ChatPage = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { activeChatId } = location.state || {};

  const [isOtherUserTyping, setIsOtherUserTyping] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const listRef = useRef();

  useEffect(() => {
    const user = AuthService.getProfile();
    console.log('Current user set:', user.data);
    setCurrentUser(user.data);
  
    const newSocket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001', {
      query: { token: AuthService.getToken() }
    });
    setSocket(newSocket);
  
    fetchChats();
  
    return () => {
      if (newSocket) newSocket.close();
    };
  }, []);

  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat._id);
      if (socket) {
        socket.emit('join chat', selectedChat._id);
      }
      // Mark the chat as read
      axios.post(`/api/message/chats/${selectedChat._id}/read`, {}, {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
    }

    return () => {
      if (socket && selectedChat) {
        socket.emit('leave chat', selectedChat._id);
      }
    };
  }, [selectedChat, socket]);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    if (socket && selectedChat) {
      socket.emit('typing', { chatId: selectedChat._id, userId: currentUser._id });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('message', handleNewMessage);
      socket.on('typing', handleTyping);

      // Add a connection event listener
      socket.on('connect', () => {
        console.log('Connected to socket server');
      });

      // Add an error event listener
      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    }

    return () => {
      if (socket) {
        socket.off('message');
        socket.off('typing');
        socket.off('connect');
        socket.off('error');
      }
    };
  }, [socket]);

  useEffect(() => {
    if (activeChatId) {
      // Select the active chat and load its messages
      setSelectedChat(chats.find(chat => chat._id === activeChatId));
      fetchMessages(activeChatId);
    }
  }, [activeChatId, chats]);

  const fetchChats = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/message/chats', {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      if (Array.isArray(response.data)) {
        setChats(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setChats([]);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
      setError('Failed to load chats. Please try again later.');
      setChats([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const response = await axios.get(`/api/message/chats/${chatId}/messages`, {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      console.log(response.data);
      setMessages(response.data.reverse());
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const deleteChat = async (chatId) => {
    try {
      await axios.delete(`/api/message/chats/${chatId}`, {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      setChats(chats.filter(chat => chat._id !== chatId));
      if (selectedChat && selectedChat._id === chatId) {
        setSelectedChat(null);
        setMessages([]);
      }

      const response = await axios.get('/api/message/chats/unread-count', {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      setCurrentUser(prevUser => ({
        ...prevUser,
        unreadMessageCount: response.data.count
      }));
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleNewMessage = (message) => {
    console.log('New message received:', message);
    setMessages((prevMessages) => [...prevMessages, message]);
    scrollToBottom();
  };

  const handleTyping = (data) => {
    if (data.userId !== currentUser._id) {
      setIsOtherUserTyping(true);
      // Clear the typing indicator after a short delay
      setTimeout(() => setIsOtherUserTyping(false), 3000);
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim() || !selectedChat) return;

    try {
      axios.post(`/api/message/chats/${selectedChat._id}/messages`, {
        content: newMessage,
        type: 'text'
      }, {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      setNewMessage('');
      // handleNewMessage(response.data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const sendNftOffer = async (nftId) => {
    try {
      const response = await axios.post(`/api/message/chats/${selectedChat._id}/offers`, {
        nftId,
        tradeDetails: 'Interested in trading this NFT'
      }, {
        headers: { Authorization: `Bearer ${AuthService.getToken()}` }
      });
      socket.emit('message', response.data);
    } catch (error) {
      console.error('Error sending NFT offer:', error);
    }
  };

  const TypingIndicator = () => (
    <Box display="flex" justifyContent="flex-start" mb={2}>
      <Paper sx={{ padding: 1, backgroundColor: 'grey.200', borderRadius: '16px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" mr={1}>
            Typing
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {[0, 1, 2].map((i) => (
              <Box
                key={i}
                sx={{
                  width: 4,
                  height: 4,
                  borderRadius: '50%',
                  backgroundColor: 'grey.500',
                  mx: 0.5,
                  animation: 'pulse 1s infinite',
                  animationDelay: `${i * 0.3}s`,
                  '@keyframes pulse': {
                    '0%': { transform: 'scale(0.8)' },
                    '50%': { transform: 'scale(1.2)' },
                    '100%': { transform: 'scale(0.8)' },
                  },
                }}
              />
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );


  const handleViewTrade = (nftId, tradeUuid, isInitiator) => {
    console.log(nftId, tradeUuid, isInitiator);
    navigate(`/trade/${nftId}/${tradeUuid}?initiator=${isInitiator}`);
  };

  const renderMessage = (message, index) => {

    if (message.type === 'offer' && message.offer.isAccepted) {
      console.log('Trade offer has been accepted');
    }
    
    const isCurrentUser = currentUser && (
      (message.sender && message.sender._id === currentUser._id) ||
      (message.sender && message.sender === currentUser._id) ||
      (!message.sender && message.userId === currentUser._id)
    );

    const otherUser = selectedChat.participants.find(p => p._id !== currentUser._id);
    const otherUserName = `${otherUser.firstName} ${otherUser.lastName}`;

    return (
      <Box
        key={message._id || index}
        display="flex"
        justifyContent={isCurrentUser ? 'flex-end' : 'flex-start'}
        mb={2}
      >
        <MessageBubble isCurrentUser={isCurrentUser}>
          {message.type === 'offer' ? (
            <Box>
              {message.offer.isAccepted ? (
                <Typography variant="subtitle2" color="textSecondary">
                  This trade offer has been accepted and is no longer available.
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle2" color={isCurrentUser ? 'inherit' : 'primary'}>
                    Trade Offer Sent
                  </Typography>
                  <Typography color={isCurrentUser ? 'inherit' : 'textPrimary'}>
                    {isCurrentUser
                      ? `You want to trade for ${otherUserName}'s ${message.offer.nftId.title}`
                      : `${otherUserName} wants to trade for your ${message.offer.nftId.title}`
                    }
                  </Typography>
                  {!isCurrentUser && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleViewTrade(message.offer.nftId._id, message.offer.tradeUuid, isCurrentUser)}
                      sx={{
                        mt: 1,
                        color: isCurrentUser ? 'inherit' : 'primary.main',
                        borderColor: isCurrentUser ? 'inherit' : 'primary.main',
                      }}
                    >
                      View Offer
                    </Button>
                  )}
                </>
              )}
            </Box>
          ) : (
            <Typography color={isCurrentUser ? 'inherit' : 'textPrimary'}>{message.content}</Typography>
          )}
          <Typography variant="caption" display="block" textAlign="right" mt={1} color={isCurrentUser ? 'inherit' : 'textSecondary'}>
            {moment(message.createdAt).format('LT')}
          </Typography>
        </MessageBubble>
      </Box>
    );
  };

  const scrollToBottom = () => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  };

  const messageAreaRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // const renderRow = ({ index, style }) => {
  //   const message = messages[messages.length - 1 - index];

  //   // Debugging logs
  //   console.log('Rendering message:', message);
  //   console.log('Current user:', currentUser);

  //   // Check if the message was sent by the current user
  //   const isCurrentUser = currentUser && (
  //     (message.sender && message.sender._id === currentUser._id) ||
  //     (message.sender && message.sender === currentUser._id) ||
  //     (!message.sender && message.userId === currentUser._id)
  //   );

  //   console.log('Is current user message:', isCurrentUser);

  //   return (
  //     <Box
  //       style={{ ...style, height: 'auto' }}
  //       display="flex"
  //       justifyContent={isCurrentUser ? 'flex-start' : 'flex-end'}
  //       mb={3}
  //     >
  //       <MessageBubble isCurrentUser={isCurrentUser}>
  //         {message.type === 'offer' ? (
  //           <Box>
  //             <Typography variant="subtitle2" color={isCurrentUser ? 'inherit' : 'primary'}>NFT Offer</Typography>
  //             {message.offer.nftId && (
  //               <img src={message.offer.nftId.imageUrl} alt="NFT" style={{ width: 100, height: 100, objectFit: 'cover', marginTop: 8, marginBottom: 8 }} />
  //             )}
  //             <Typography color={isCurrentUser ? 'inherit' : 'textPrimary'}>{message.offer.tradeDetails}</Typography>
  //             <Button
  //               variant="outlined"
  //               size="small"
  //               href={message.offer.tradePageLink}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //               sx={{
  //                 mt: 1,
  //                 color: isCurrentUser ? 'inherit' : 'primary.main',
  //                 borderColor: isCurrentUser ? 'inherit' : 'primary.main',
  //               }}
  //             >
  //               View Trade
  //             </Button>
  //           </Box>
  //         ) : (
  //           <Typography color={isCurrentUser ? 'inherit' : 'textPrimary'}>{message.content}</Typography>
  //         )}
  //         <Typography variant="caption" display="block" textAlign="right" mt={1} color={isCurrentUser ? 'inherit' : 'textSecondary'}>
  //           {moment(message.createdAt).format('LT')}
  //         </Typography>
  //       </MessageBubble>
  //     </Box>
  //   );
  // };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [messages]);

  if (!AuthService.loggedIn()) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5">Please log in to access the chat.</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2} sx={{ height: '100vh', p: 2 }}>
      <Grid item xs={12} sm={4} md={3}>
        <Paper elevation={3} sx={{ height: '100%', overflowY: 'auto' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box p={2}>
              <Typography color="error">{error}</Typography>
            </Box>
          ) : chats.length === 0 ? (
            <Box p={2}>
              <Typography>No chats available. Start a new conversation!</Typography>
            </Box>
          ) : (
            <List>
              {chats.map((chat) => (
                <ListItem
                  button
                  key={chat._id}
                  selected={selectedChat && selectedChat._id === chat._id}
                  onClick={() => setSelectedChat(chat)}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteChat(chat._id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{chat.participants[0].firstName[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${chat.participants.find(p => p._id !== currentUser._id).firstName} ${chat.participants.find(p => p._id !== currentUser._id).lastName}`}
                    secondary={chat.lastMessage ? chat.lastMessage.content : 'No messages yet'}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <ChatContainer elevation={3}>
          {selectedChat ? (
            <>
              <Typography variant="h6" gutterBottom>
                {selectedChat && `${selectedChat.participants.find(p => p._id !== currentUser._id).firstName} ${selectedChat.participants.find(p => p._id !== currentUser._id).lastName}`}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <MessageArea ref={messageAreaRef}>
                {messages.map((message, index) => renderMessage(message, index))}
                {isOtherUserTyping && <TypingIndicator />}
              </MessageArea>
              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Type a message"
                  value={newMessage}
                  onChange={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                  sx={{ mr: 1 }}
                />
                <IconButton color="primary" onClick={sendMessage}>
                  <SendIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <Typography variant="h6" align="center">
              Select a chat to start messaging
            </Typography>
          )}
        </ChatContainer>
      </Grid>
    </Grid>
  );
};

export default ChatPage;